::-webkit-scrollbar{
  width: 10px;
  height: auto;
}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px  rgb(207, 5, 5);
}

::-webkit-scrollbar-thumb{
  background-color: tomato;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: rgb(9, 24, 235);
}

#dark{
  background-color: #46045E;
  color:white;
}
