@import '../../../setings.scss';

.contact-section{
  position: relative;
  height: 650px;
  margin-top: 200px;
  border-radius: 10px;
  z-index: 998;
  margin-bottom: 300px;


  -webkit-box-shadow: 24px 24px 39px -18px rgba(66, 68, 90, 1);
  -moz-box-shadow: 24px 24px 39px -18px rgba(66, 68, 90, 1);
  box-shadow: 24px 24px 39px -18px rgba(66, 68, 90, 1);
  .contact-form-img{
      
    img{
      height: 600px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      object-position: -169px;
    }
  }
  .contact-form{
    
    input{
      height: 50px;
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
    .custom-select-tag{
      height: 50px;
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      margin-bottom: 20px;
      background-color: white;
    }
    textarea{
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
    .button-submit{
      
        p{
        background-color: black;
        padding: 10px;
        border-radius: 30px;
        color: rgb(95, 199, 35);
        width: 50%;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        margin: auto;
        font-family: $TechFont;
        cursor: pointer;
        
        &:hover{
          background-color: rgb(180, 54, 54);
          transition-duration: 1s;
          color: black;
        }
        }
    }
  }
}

#dark{
  .contact-section{
    box-shadow: none;
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
    .row{
      background-color: #38004c;
    }
  
  .contact-form{
    background-color: #38004c;
    input,textarea,select{
      background-color: #9603cc;
       border: yellow 2px solid; 
       color: white;
    }
  }
}
}
@media(max-width: 1000px){
  .contact-form-design{
    margin-bottom: 90px;
    z-index: 1;
  }
  form{
    margin-top: 50px;
    z-index: 1;
  }
  
  .custom-select-tag{
    border: grey 1px solidf;
    border-radius: 5px;
  }
  .contact-form-img{
      
    img{
      width: 100%;
      height: 100%; /* Dodaj 100% wysokości, aby utrzymać proporcje obrazu */
      object-fit: cover;
      border-radius: 10px;
      object-position: -55px;
    }

  }
}

