@import '../../../setings.scss';

.tech-stack-section{
  margin-top: 40px;
  height: auto;

  .tech-content{
    padding: 10px;
    margin: 50px;
    -webkit-box-shadow: 4px 7px 32px -1px rgba(66, 68, 90, 1);
    -moz-box-shadow: 4px 7px 32px -1px rgba(66, 68, 90, 1);
    box-shadow: 4px 7px 32px -1px rgba(66, 68, 90, 1);
    border-radius: 5px;

    &:hover{
      background-color: $button-hire-me;
      color: $title-color;
      transition-duration:  1s;

      -webkit-box-shadow: -14px 20px 36px 2px rgba(66, 68, 90, 1);
      -moz-box-shadow: -14px 20px 36px 2px rgba(66, 68, 90, 1);
      box-shadow: -14px 20px 36px 2px rgba(66, 68, 90, 1);
    }
    .tech-number{
      border-radius: 90px;
      height: 50px;
      width: 50px;
      color: white;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: block;
      margin: 0 auto ;
      margin-top: -30px;
      line-height: 50px;
    }

    p{
      text-align:  center;
      padding-top: 30px;
      font-size: 20px;
      font-family: $TechFont;
    }
  }

  .load-more-tech-stack{
    display: table;
    background-color: tomato;
    width: 150px;
    padding: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    border-radius: 5px;
    margin-bottom: 40px;
    cursor: pointer;

    &:hover{
      background-color:rgb(58, 99, 211);
    }
  }
}

#dark .tech-content{
  background-color: #38004c;
  color: white;
  box-shadow: none;
  background-color: #38004c;
  -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
  -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
  box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 42);
  &:hover{
    background-color: tomato;
    transition-duration: 2s;
  }
}

