@import '../../../setings.scss';

.home{
  height: 400px;
  color: $title-color;

  .home-content{
    z-index: 2;
    //margin: auto;
    padding: 10px;
    margin-top: 100px;
    h2{
      margin-top: 20px;
      color: $pic-border;
    }

  }
  .button-for-action{
    display: flex;
    z-index: 1;
    .button{
      font-weight: bold;
      text-align: center;
      width: 180px;
      padding: 10px;
      border-radius: 90px;
      cursor: pointer;
      margin-top: 10px;
      border: 3px solid $title-color;

    }
    .hire-me-button{
      background-color: $button-hire-me;
      
      &:hover{
        background-color: $title-color;
        color: $button-hire-me;
        width: 250px;
        transition-duration: 1s;
        border: 3px solid $button-hire-me;

      }
    }
    .get-resume-button{
      background-color: $button-resume;

      &:hover{
        background-color: $title-color;
        color: $button-resume;
        width: 250px;
        transition-duration: 1s;
        border: 3px solid $button-resume;
        a{
          color:$button-resume;
        }
      }

      a{
        text-decoration: none;
        color:$title-color;
        transition: color 0.3s;
        font-weight: bold;
      }
    }
  }

  .theme-change{
    justify-content: center;
    height: 53px;
    width: 50px;
    background-color: blue;
    right: 0;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    position: fixed;
    top:150;
    border-radius: 5px;
    margin-top: 250px;

    .sun-theme-icon{
      animation: rotation infinite 5s linear;
    }
    @keyframes rotation {
      
      from{
        transform: rotate(0deg);
      }
      to{
        transform: rotate(360deg);
      }
    }
  }
}
@media(max-width: 1600px) and (min-width: 1001px){
  .home-content{
    margin: auto auto auto 250px;
  }
}

@media(max-width: 1000px){
  .theme-change{
    display: none;
  }
}

