@import '../../../setings.scss';

.workexperience-section{
  margin-top: 70px;
  .timeline-section{
    margin-top: 20px;
  }
  .tech-skills{
    p{
      background-color: black;
      border-radius: 90px;
      color: white;
      padding: 10px;
      text-align: center;

      &:hover{
        background-color: gold;
        color: black;
        cursor: pointer;
      }

    }
  }

  .date-class{
    color: black;
  }

}
#dark .date-class{
  color: white;
}