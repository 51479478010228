@import '../../../setings.scss';

.container-fluid{
  padding: 0 0 !important;
  overflow:  hidden;
  
}

.sidebar-section{
  position: relative;
  display: flex;
  background-color: $sidebar-color;
  z-index: 9999 !important;

  .sidebar{
    height: 100vh;
    background-color: $sidebar-color;
    width: 95px;
    position: fixed;
  }
  .sidebar-expand{
    width: 230px;
  }

  .icon-for-sidebar-expand-and-colapse{
    display: flex;
    align-items: right;
    justify-content: right;
    cursor: pointer;
    p{
      svg{
        background-color: $sidebar-arrow;
        padding: 5px;
        color: white;
        border-radius: 5px;
        margin-top: 50px;
      }
    }
  }
}


@media( max-width:1000px ){
  .sidebar{
    display: none;
  }
}
